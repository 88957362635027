import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import ClosedCaptionDisabledIcon from "@mui/icons-material/ClosedCaptionDisabled";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {
	Avatar,
	Badge,
	Card,
	CircularProgress,
	IconButton,
	Typography,
	Select,
	MenuItem,
	FormControl,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { compile } from "handlebars";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SpeechRecognition, {
	useSpeechRecognition,
} from "react-speech-recognition";
import { useFirebase } from "../helpers/firebase-context";
import { OpenAIMessagesProp, useSharktank } from "../helpers/sharktank-context";
import { Personality } from "../models/personality.interfaces";
import CameraAvatar from "./elements/CameraAvatar";
import "./pitch-simulation.scss";
import { SelectChangeEvent } from '@mui/material/Select';

const conversationReponses = [
	{
		type: "initial",
		instructions:
			"Your task: Treat this as a personal conversation. Start by figuring out if the user already knows you or if you’re meeting for the first time. Ask natural, open-ended questions to understand why the user wants to talk with you. Don’t just ask questions. Also offer encouragement, show support, and be a good listener. Let the conversation flow easily and naturally. The goal is to make the user feel connected to you and ensure that this interaction feels meaningful and personal to them.",
	},
	{
		type: "final",
		instructions:
			"Your task: Make the user feel connected to you, as if they were talking to you in person and as a real human. You want the user to feel heard, understood, validated, and connected.",
	},
];

export default function PitchSimulation() {
	// state for personality for communication
	const [personality, setPersonality] = useState<Personality | null>(null);

	// state prompts that will be used for conversation
	const [prompts, setPrompts] = useState<{ [key: string]: string }>({
		start: "The user just started talking to you, be friendly and ask for a second or moment to get yourself ready.",
		between: "In one sentece, you need a moment",
	});

	// state for recording status
	const [recording, setRecording] = useState(false);

	// state to store prompt
	const [prompt, setPrompt] = useState("");


	// get user and db from firebase context
	const {
		personalities,
		avatars,
		sendOpenAIRequest,
		sendDIDRequest,
		sendElevenLabsStreamRequest,
		images,
		selectedCategory,
	} = useSharktank();

	console.log(">> selectedCategory", selectedCategory);

	// load user from firebase
	const { user, getConfig } = useFirebase();

	// read personKey from URL based on path struture /pitch/:personKey
	const { personKey } = useParams();

	// load speech recognition
	const {
		transcript,
		resetTranscript,
		browserSupportsSpeechRecognition: browserSupport,
		finalTranscript,
	} = useSpeechRecognition();

	// state the conversation
	const [conversation, updateConversation] = useState<Conversation[]>([]);

	// state whos turn is to talk
	const [turn, setTurn] = useState<"user" | "bot" | null>(null);

	// state for video URL
	const [videoUrl, setVideoUrl] = useState("");

	// state instructions for conversation
	const [instructions, setInstructions] = useState<OpenAIMessagesProp[]>([]);

	// state imageUrl for avatar
	const [imageUrl, setImageUrl] = useState("");

	// state video queue
	const [videoQueue, setVideoQueue] = useState<VideoQueueProps[]>([]);

	// use effect to cound how many loading
	const [loading, setLoading] = useState(0);

	// use effect to state if it's a founder
	const [founder, setFounder] = useState(false);

	// use state to manage chat history visibility
	const [chatVisible, setChatVisible] = useState(true);

	// use state to manage video chat visibility
	const [videoChatActive, setVideoChatActive] = useState(true);

	// Add this state inside the component
	const [globalSelectedOption, setGlobalSelectedOption] = useState('');

	const handleOptionChange = (event: SelectChangeEvent<string>) => {
		const value = event.target.value;
		console.log(">> Selected option:", value);
		setGlobalSelectedOption(value);
	};

	// Use an effect to update the image when the selected option changes
	useEffect(() => {
		if (personKey && globalSelectedOption && personality) {
			console.log(">> Updating image based on selected option:", globalSelectedOption);
			let newImageUrl = '';
			if (personality.storyteller && personality.storyteller[globalSelectedOption]) {
				newImageUrl = personality.storyteller[globalSelectedOption];
			} else {
				// Fallback to default image if the selected option doesn't have an image
				newImageUrl = personality.storyteller?.["mom"] || 
											personality.selfies?.[0] || 
											avatars[personKey]?.proxy || 
											personality.imageUrl || 
											"";
			}
			console.log(">> New image URL:", newImageUrl);
			// if image does not start with http, then find it in images
			if (newImageUrl.indexOf("http") !== 0) {
				const img = images.find(
					(img) =>
						img.name === newImageUrl 
						//img.path.indexOf(newImageUrl) > -1 ||
						//img.name.indexOf(personKey) > -1
				);
				newImageUrl = img?.url || newImageUrl;
			}
			console.log(">> after checking for http", newImageUrl);

			setImageUrl(newImageUrl);
		}
	}, [personKey, globalSelectedOption, personality, avatars, images]);

	// detect personality
	useEffect(() => {
		console.log("load page", personKey);

		// load personality from firebase based on personKey
		if (personKey && personalities[personKey]) {
			const avatar = avatars[personKey];
			const person = personalities[personKey];

			console.log("personality", { person, avatar });
			setPersonality(person || null);
			// setAvatar(avatars[personKey] || null);

			// Update the image selection logic
			let selectedOption: string | undefined; // Declare selectedOption
			let image = person.storyteller?.["mom"] || "";
			console.log(">> selectedOption", selectedOption);
			console.log(">> person.storyteller", person.storyteller);
			console.log(">> image after hard coding mom and before selected option", image);
			if (selectedOption) {
				image = person.storyteller?.[selectedOption] || image;
			} else {
				image = image || (person.selfies && person.selfies[0]) ||
					avatars[personKey].proxy ||
					person.imageUrl ||
					"";
			}
			// if image does not start with http, then find it in images
			if (image.indexOf("http") !== 0) {
				const img = images.find(
					(img) =>
						img.name === image ||
						img.path.indexOf(image) > -1 ||
						img.name.indexOf(personKey) > -1
				);
				image = img?.url || image;
			}
			console.log(">> image after selected option", image);
			setImageUrl(image);

			// check if person is a founder
			if (/vitali_korezki|amanda_peterson/.test(personKey)) {
				setFounder(true);
			}

			// update prompts
			if (person.prompts) {
				setPrompts((old) => Object.assign(old, person.prompts));
			}
		}

		// reset transcript on page load
		return () => {
			SpeechRecognition.stopListening();
			resetTranscript();
		};
	}, [personKey, personalities, resetTranscript, avatars, images]);

	// use effect to get config
	useEffect(() => {
		if (prompt || !personality) {
			return;
		}
		// set dummy prompt just to prevent multiple calls
		setPrompt("Please wait...");

		// request prompt from remote config
		getConfig("sharktank_prompt", "string").then((apiprompt) => {
			// console.log("sharktank_prompt from firebase", apiprompt);
			if (apiprompt) {
				// Compile the template
				const template = compile(apiprompt);
				apiprompt = template({ personality });
				console.log("final prompt: " + apiprompt);
				setPrompt(apiprompt);
			} else {
				setPrompt(`${personality?.bio} ${personality?.task}`);
			}
		});
	}, [getConfig, personality, prompt]);

	// use effect to assign next video URL from queue if the videoURL gets empty
	useEffect(() => {
		if (!recording && !videoUrl && videoQueue.length) {
			const nextVideo = videoQueue.shift();
			setVideoUrl(nextVideo?.url || "");

			// add user message to conversation
			updateConversation((old) => [
				...old,
				{
					sender: "bot",
					name: personality?.name || "",
					message: nextVideo?.transcript || "",
				},
			]);
		}
	}, [
		videoQueue,
		videoUrl,
		personality,
		updateConversation,
		setVideoUrl,
		recording,
	]);

	const audioContextRef = useRef<AudioContext | null>(null);
	const sourceRef = useRef<AudioBufferSourceNode | null>(null);

	// create a callback to request next video
	const sendRequestToAPI = useCallback(
		(messages: OpenAIMessagesProp[]) => {
			// update loading
			setLoading((old) => old + 1);

			// send request to OpenAI
			sendOpenAIRequest(messages).then((response) => {
				console.log("response from OpenAI", response);

				// build regex to remove [personality.name + ":"] or each indivudial name part with ":"
				const regex = [
					personality?.name + ":" || "",
					personality?.name?.split(" ")[0] + ":" || "",
					personality?.name?.split(" ")[1] + ":" || "",
				].join("|");

				// fix OpenAI issue
				response = response.replace(new RegExp(regex, "ig"), "");

				console.log({ videoChatActive });

				// if video chat is deactivated, send it to elevenlabs only
				if (!videoChatActive) {
					// send request to ElevenLabs
					return sendElevenLabsStreamRequest({
						input: response,
						voiceId: personality?.voiceId || "",
					}).then((arrayBuffer) => {
						console.log("ElevenLabs Response:", arrayBuffer);
						// update loading
						setLoading((old) => old - 1);

						// set up audio context
						const audioContext =
							audioContextRef.current ||
							new window.AudioContext();
						audioContextRef.current = audioContext;

						// create audio buffer source
						const source = audioContext.createBufferSource();

						// decode audio buffer
						audioContext.decodeAudioData(arrayBuffer, (buffer) => {
							source.buffer = buffer;
							source.connect(audioContext.destination);
							source.start(0);
							sourceRef.current = source;
						});

						// add user message to conversation
						updateConversation((old) => [
							...old,
							{
								sender: "bot",
								name: personality?.name || "",
								message: response || "",
							},
						]);
					});
				}

				// send request to D-ID to create video
				return sendDIDRequest({
					input: response,
					imageUrl,
					voiceId: personality?.voiceId || "",
				}).then((videoUrl) => {
					console.log("D-ID Response:", videoUrl);
					// add video to the queue
					setVideoQueue((old) =>
						old.concat({
							url: videoUrl,
							transcript: response,
						})
					);
					// update loading
					setLoading((old) => old - 1);
				});
			});
		},
		[
			sendOpenAIRequest,
			sendDIDRequest,
			imageUrl,
			personality,
			videoChatActive,
			sendElevenLabsStreamRequest,
		]
	);


	// define click listener for interaction controls
	const clickActions = useCallback(
		(action: string) => {
			console.log("Interaction action", action);
			// limit user to 2 messages
			// const limit = 2;
			const numberMessages = conversation.filter(
				(c) => c.sender === "user"
			).length;

			// handle actions based on type of action requested
			switch (action) {
				case "startRecording":
					// if no previous messages then generate transition in the background
					if (!numberMessages) {
						// instructions for OpenAI
						const messages: OpenAIMessagesProp[] = [
							{
								role: "system",
								content: prompt,
							},
						];
						if (!founder && videoChatActive) {
							messages.push({
								role: "assistant",
								content: prompts.start,
							});
							// send request to OpenAI
							sendRequestToAPI(messages);
						}
						setInstructions(messages);
					}

					// start recording if not recording and limit not reached
					if (!recording) {
						resetTranscript();
						setTurn("user");
						setRecording(true);
						SpeechRecognition.startListening({ continuous: true });
					}
					break;
				case "stopRecording":
					setRecording(false);
					SpeechRecognition.stopListening();
					break;
				// create case for hiding chat history
				case "toggleChatVisible":
					// toggle chat history visibility
					setChatVisible((old) => !old);
					break;
				// create case for hiding video chat
				case "toggleVideoChat":
					// toggle video chat visibility
					setVideoChatActive((old) => !old);
					break;
				// create case for reading book
				case "startReading":
					console.log("sendDIDRequest is", sendDIDRequest);
					console.log("Personality state:", personality);

					// Determine the selected voice
					const selectedVoice = personality?.storyteller_voices?.[globalSelectedOption] || personality?.voiceId || "";
					console.log(">> selectedVoice", selectedVoice);

					if (!videoChatActive) {
						return sendElevenLabsStreamRequest({
							input: personality?.transcript || "",
							voiceId: selectedVoice,
						}).then((arrayBuffer) => {
							console.log("ElevenLabs Response:", arrayBuffer);

							const audioContext = audioContextRef.current || new window.AudioContext();
							audioContextRef.current = audioContext;

							const source = audioContext.createBufferSource();
							audioContext.decodeAudioData(arrayBuffer, (buffer) => {
								source.buffer = buffer;
								source.connect(audioContext.destination);
								source.start(0);
								sourceRef.current = source;
							});
						});
					} else {
						console.log(">> video chat is active");
					// Create selectedImage variable
					let selectedImage = imageUrl || personality?.selfies?.[0] || "";

					// Convert selectedImage to proxy URL format if it's not already
					if (selectedImage && !selectedImage.startsWith("https://proxyimage-rsqadpyqda-uc.a.run.app/")) {
						// Extract the path from the Firebase URL
						const urlParts = selectedImage.split('/o/');
						if (urlParts.length > 1) {
							const pathPart = urlParts[1].split('?')[0]; // Remove query params
							const decodedPath = decodeURIComponent(pathPart);
							selectedImage = `https://proxyimage-rsqadpyqda-uc.a.run.app/${decodedPath}`;
						}
					}

					console.log(">> Selected Image:", selectedImage);

					if (sendDIDRequest) {
						sendDIDRequest({
							input: personality?.transcript || "",
							imageUrl: selectedImage, // Use the new selectedImage here
							voiceId: selectedVoice,
						}).then((videoUrl) => {
							console.log("Payload sent to D-ID:", {
								input: personality?.transcript || "",
								imageUrl: selectedImage,
								voiceId: selectedVoice,
							});
							console.log("sendDIDRequest called in startReading case");
							console.log("D-ID Response:", videoUrl);

							// Update the video URL state
							setVideoUrl(videoUrl);
						});
					} else {
						console.error("sendDIDRequest is not available.");    
					}
					}

					break;
				default:
					break;
			}
		},
		[
			conversation,
			recording,
			resetTranscript,
			setInstructions,
			prompt,
			sendRequestToAPI,
			founder,
			videoChatActive,
			prompts,
			sendDIDRequest,
			personality,
			imageUrl,
			globalSelectedOption,
			sendElevenLabsStreamRequest,
		]
	);

	// use effect to listen to speech recognition
	useEffect(() => {
		if (!recording && finalTranscript && turn === "user") {
			console.log("Speech recognition", recording, finalTranscript);

			// add user message to conversation
			updateConversation((old) => [
				...old,
				{
					sender: "user",
					name: "", //user?.displayName || "User",
					message: transcript,
					visible: true,
				},
			]);

			let content = "Participant:" + finalTranscript;
			const numberMessages = conversation.filter(
				(c) => c.sender === "user"
			).length;
			if (!numberMessages && !founder) {
				content += " \n\n" + conversationReponses[0].instructions;
			} else if (!founder) {
				content += " \n\n" + conversationReponses[1].instructions;
			}

			// update instructions for OpenAI
			const messages: OpenAIMessagesProp[] = [
				...(instructions.filter((m) => m.role !== "assistant") || []),
				{
					role: "user",
					content,
				},
			];

			setInstructions(messages);
			// send request to OpenAI
			sendRequestToAPI(messages);

			// pass turn to bot
			setTurn("bot");
		}
	}, [
		turn,
		finalTranscript,
		recording,
		transcript,
		conversation,
		instructions,
		founder,
		updateConversation,
		setInstructions,
		sendRequestToAPI,
		setTurn,
	]);

	return (
		<div className="pitchPage">
			<div className="pitchPageContent">
				<div className="vsNameContainer">
					<div className="vsNameBadge">
						<strong>{personality?.name}</strong>,{" "}
						{personality?.subtitle}
					</div>
				</div>

				<div className="vsAvatar" key={personKey}>
					{!videoUrl && (
						<Avatar
							alt={personality?.name}
							src={imageUrl}
							sx={{ width: 450, height: 450 }}
							className="avatarImage"
						/>
					)}
					{/* show video player instead of avatar */}
					{videoUrl && (
						<Avatar
							sx={{ width: 450, height: 450 }}
							className="avatarImage"
						>
							<video
								className="avatarVideo"
								src={videoUrl}
								controls
								autoPlay
								onEnded={() => setVideoUrl("")}
							></video>
						</Avatar>
					)}
					{!videoUrl && loading && (
						<div className="recordingBox">
							<div>{personality?.name} is thinking...</div>
						</div>
					)}
					{browserSupport && turn === "user" && !videoUrl && (
						<div className="recordingBox">
							<div>{user?.displayName} is talking...</div>
							<Typography fontFamily="Raleway" variant="body1">
								{transcript}
							</Typography>
						</div>
					)}
					{!browserSupport && (
						<Typography fontFamily="Raleway" variant="h6">
							Speech recognition not supported
						</Typography>
					)}
				</div>

				{/* Camera Avatar */}
				<div className="cameraAvatar">
					<CameraAvatar />
				</div>

				<div className="interactionControlsWrapper">
					<InteractionControls
						chatVisible={chatVisible}
						onClick={clickActions}
						videoChatActive={videoChatActive}
						selectedOption={globalSelectedOption}
						onSelectChange={handleOptionChange}
					/>
				</div>
			</div>

			{chatVisible && <ChatHistory conversation={conversation} />}

			{/* preload videos in the background based on queue */}
			{videoQueue.map((video, index) => (
				<video
					key={index}
					src={video.url}
					preload="auto"
					muted
					className="videopreload"
				></video>
			))}
		</div>
	);
}

/**
 * Define action types for interaction controls
 */
type ActionTypes =
	| "startRecording"
	| "stopRecording"
	| "toggleChatVisible"
	| "toggleVideoChat"
	| "startReading";

/**
 * Interaction controls for pitch simulation
 */
type InteractionControlsProps = {
	chatVisible?: boolean;
	videoChatActive?: boolean;
	onClick?: (action: ActionTypes) => void;
};

/**
 * Helper to style the interaction controls
 * @param param0 Properties for interaction controls
 * @returns Interaction controls for pitch simulation
 */
function InteractionControls({
	chatVisible,
	videoChatActive,
	onClick,
	selectedOption,
	onSelectChange,
}: InteractionControlsProps & {
	selectedOption: string;
	onSelectChange: (event: SelectChangeEvent<string>) => void;
}) {
	// get listening status from speech recognition
	const { listening } = useSpeechRecognition();

	// track progress of recording for 45 seconds
	const [progress, setProgress] = useState(0);

	// custom callback for intercations
	const clickActions = useCallback(
		(action: ActionTypes) => {
			onClick && onClick(action);
			setProgress(0);
		},
		[onClick]
	);

	// update progress every second
	useEffect(() => {
		if (listening) {
			const maxTime = 45;
			const interval = setInterval(() => {
				setProgress((oldProgress) => {
					const newProgress = oldProgress + 100 / maxTime;
					if (newProgress >= 100) {
						clickActions("stopRecording");
					}
					return newProgress;
				});
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [listening, clickActions]);

	const { selectedCategory } = useSharktank();

	return (
		<Card
			variant="outlined"
			sx={{
				display: "flex",
				justifyContent: "center", // Center items horizontally
				alignItems: "center", // Center items vertically (optional)
				color: "text.secondary",
				"& svg": {
					m: 1,
				},
				"& hr": {
					mx: 0.5,
				},
			}}
			className="interactionControls"
		>
			{/* Conditional rendering based on selectedCategory */}
			{selectedCategory === "story_time" ? (
				<>
					<FormControl fullWidth>
						<Select
							labelId="select-label"
							value={selectedOption}
							onChange={onSelectChange}
							displayEmpty
							variant="outlined"
							sx={{ 
								'& .MuiOutlinedInput-notchedOutline': { border: 'none' }, 
								'&:focus': { outline: 'none' },
								'& .MuiSelect-select': { padding: '25px 10px' } // Set padding here
							}}
						>
							<MenuItem value="">
								Let's Read
							</MenuItem>
							<MenuItem value="mom">Read with Mom</MenuItem>
							<MenuItem value="dad">Read with Dad</MenuItem>
							<MenuItem value="grandma">Read with Grandma Vicky</MenuItem>
							<MenuItem value="pop">Read with Pop</MenuItem>
							<MenuItem value="aunt">Read with Aunt Amanda</MenuItem>
						</Select>
					</FormControl>
					<IconButton aria-label="PlayCircleIcon" size="large"
						onClick={() => clickActions("startReading")}
					>
						<PlayCircleIcon />
					</IconButton>
					<IconButton
						aria-label="VideocamIcon"
						size="large"
						onClick={() => clickActions("toggleVideoChat")}
					>
						{videoChatActive ? <VideocamIcon /> : <VideocamOffIcon />}
					</IconButton>
					<IconButton
						aria-label="ClosedCaptionIcon"
						size="large"
						onClick={() => clickActions("toggleChatVisible")}
					>
						{!chatVisible ? (
							<ClosedCaptionDisabledIcon />
						) : (
							<ClosedCaptionIcon />
						)}
					</IconButton>
					<IconButton aria-label="HeartIcon" size="large">
						❤️ {/* Heart emoji */}
					</IconButton>
				</>
			) : (
				<>
					<IconButton
						aria-label="KeyboardVoiceIcon"
						size="large"
						onClick={() => clickActions("startRecording")}
					>
						<KeyboardVoiceIcon />
						{listening && (
							<CircularProgress
								size={75}
								sx={{
									color: green[500],
									position: "absolute",
									top: -5,
									left: -5,
									zIndex: 1,
								}}
								variant="determinate"
								value={progress}
							/>
						)}
					</IconButton>
					<IconButton
						aria-label="StopCircleIcon"
						size="large"
						onClick={() => clickActions("stopRecording")}
					>
						<StopCircleIcon />
					</IconButton>
					<IconButton
						aria-label="VideocamIcon"
						size="large"
						onClick={() => clickActions("toggleVideoChat")}
					>
						{videoChatActive ? <VideocamIcon /> : <VideocamOffIcon />}
					</IconButton>
				</>
			)}
		</Card>
	);
}

/**
 * Define conversation type for chat history
 */
type Conversation = {
	sender: "user" | "bot";
	name: string;
	message: string;
	visible?: boolean;
};

type VideoQueueProps = {
	url: string;
	transcript: string;
};

/**
 * Chat history for pitch simulation
 */
type ChatHistoryProps = {
	conversation: Conversation[];
};

/**
 * Helper to style the chat history
 * @param param0 Props for chat history
 * @returns Return chat history for pitch simulation
 */
function ChatHistory({ conversation }: ChatHistoryProps) {
	return (
		<div className="chatHistory">
			{conversation.map((message, index) => (
				<div key={index} className={`chatMessage ${message.sender}`}>
					<Badge
						anchorOrigin={{
							vertical: "top",
							horizontal:
								message.sender === "user" ? "right" : "left",
						}}
						color="secondary"
						badgeContent={message.name}
						sx={{
							mt: -2,
						}}
					>
						<p>{message.message}</p>
					</Badge>
				</div>
			))}
		</div>
	);
}
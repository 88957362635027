import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { useFirebase } from "./firebase-context";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { Personalities, Personality } from "../models/personality.interfaces";
import { collection, getDocs } from "firebase/firestore";

type ContextProps = {
	loadImages: (path: string) => Promise<ImagesProps[]>;
	personalities: Personalities;
	images: ImagesProps[];
	avatars: ImageCollection;
	sendDIDRequest: (props: DIDProps) => Promise<string>;
	sendOpenAIRequest: (messages: OpenAIMessagesProp[]) => Promise<string>;
	sendElevenLabsStreamRequest: (props: ELVLProps) => Promise<ArrayBuffer>;
	selectedCategory: string; // New field added
	setSelectedCategory: (category: string) => void;
};

/**
 * Type for images
 */
export type ImagesProps = {
	name: string;
	path: string;
	url: string;
	proxy: string;
};

/**
 * Type for image collection
 */
type ImageCollection = { [personKey: string]: ImagesProps };

// create firebase context
const SharktankContext = createContext<ContextProps | undefined>(undefined);

/**
 * Custom hook to use firebase context
 * @returns ContextProps for sharktank
 */
export const useSharktank = (): ContextProps => {
	const context = useContext(SharktankContext);
	if (!context) {
		throw new Error("useFirebase must be used within a FirebaseProvider");
	}
	return context;
};

/**
 * Type OpenAI messages
 */
export type OpenAIMessagesProp = {
	role: "system" | "user" | "assistant";
	content: string;
};

type DIDProps = {
	input: string;
	voiceId: string;
	imageUrl: string;
};

type ELVLProps = {
	input: string;
	voiceId: string;
};

/**
 * Provider props
 */
type ProviderProps = {
	children: React.ReactNode;
};

// create provider for firebase context to wrap the app
export const SharktankProvider = ({ children }: ProviderProps) => {
	// Initialize storage
	const { user, db, storage, getConfig } = useFirebase();

	// state for personalities
	const [personalities, setPersonalities] = useState<Personalities>({});

	// state to store images for personalities
	const [avatars, setAvatars] = useState<ImageCollection>({});

	// state to store selfies for personalities
	const [images, setImages] = useState<ImagesProps[]>([]);

	// state for selected category
	const [selectedCategory, setSelectedCategory] = useState<string>(""); // Initialize state for selectedCategory

	/**
	 * Helper method to load images from firebase storage
	 * @param path Storage path to load images from
	 * @returns Array of images with name, path and URL
	 */
	const loadImages = useCallback(
		async (path: string) => {
			const imagesRef = ref(storage, path);

			// load images from firebase storage
			return listAll(imagesRef).then((list) => {
				// create a list with promises to load image URLs
				const promises: Promise<ImagesProps>[] = [];
				list.items.forEach((itemRef) => {
					promises.push(
						getDownloadURL(itemRef).then((url) => {
							return {
								name: itemRef.name,
								path: itemRef.fullPath,
								proxy:
									"https://proxyimage-rsqadpyqda-uc.a.run.app/" +
									itemRef.fullPath,
								url,
							};
						})
					);
				});
				return Promise.all(promises);
			});
		},
		[storage]
	);

	const [keys, setKeys] = useState({
		OPENAI_API_KEY: "",
		ELEVENLABS_API_KEY: "",
		DID_API_KEY: "",
	});

	// use effect to load keys
	useEffect(() => {
		const k = {
			OPENAI_API_KEY: "",
			ELEVENLABS_API_KEY: "",
			DID_API_KEY: "",
		};
		// load keys
		const fetchKeys = async () => {
			const newKeys: any = { ...k };
			for (const key of Object.keys(newKeys)) {
				newKeys[key] = await getConfig(key, "string");
			}
			setKeys(newKeys);
		};

		fetchKeys();
	}, [getConfig, setKeys]);

	// load data from firebase
	useEffect(() => {
		// check if user is logged in
		if (!user) {
			return;
		}

		// load images and map to personalities
		loadImages("personalities").then(async (imgs) => {
			console.log("images", imgs);

			// store images
			setImages(imgs);
			const avtrs = {} as ImageCollection;

			// load personalities
			const collectionRef = collection(db, "personalities");
			const querySnapshot = await getDocs(collectionRef);
			const personalities: Personalities = {};
			querySnapshot.forEach((doc) => {
				const item = (personalities[doc.id] =
					doc.data() as Personality);

				// update image path
				const avatar = imgs.find(
					(img) =>
						(item.imageUrl && img.name === item.imageUrl) ||
						(item.imageUrl &&
							img.path.indexOf(item.imageUrl) > -1) ||
						img.name.indexOf(doc.id) > -1
				);

				// update image URL and store the reference to the images object
				if (avatar) {
					item.imageUrl = avatar.url;
					avtrs[doc.id] = avatar;
				}

				// update selfies path
				item.selfies = (item.selfies || []).map((selfie) => {
					return (
						imgs.find(
							(img) =>
								(selfie && img.name === selfie) ||
								(selfie && img.path.indexOf(selfie) > -1)
						)?.proxy || selfie
					);
				});
			});
			console.log("avtrs", avtrs);

			// store personalities
			setPersonalities(personalities);
			setAvatars(avtrs);
		});
	}, [user, db, storage, loadImages]);

	// send request to OpenAI
	const sendOpenAIRequest = async (messages: OpenAIMessagesProp[]) => {
		// send request to OpenAI
		const url = "https://api.openai.com/v1/chat/completions";
		const payload = {
			model: "gpt-4o",
			//model: "gpt-3.5-turbo",
			// model: "gpt-4-turbo",
			messages,
		};
		const apikey =
			keys.OPENAI_API_KEY ||
			(await getConfig("OPENAI_API_KEY", "string"));

		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ` + apikey,
			},
			body: JSON.stringify(payload),
			// mode: "no-cors",
		}).then(async (apiresponse) => {
			const data = await apiresponse.json();
			console.log("Response:", data);

			// Or adjust based on actual response structure
			return data.choices[0].message.content.trim();
		});
	};

	// send elevenlabs request
	const sendElevenLabsStreamRequest = async ({
		input,
		voiceId,
	}: ELVLProps) => {
		const options = {
			method: "POST",
			headers: {
				"xi-api-key":
					keys.ELEVENLABS_API_KEY ||
					(await getConfig("ELEVENLABS_API_KEY", "string")),
				"Content-Type": "application/json",
			},
			// '{"text":"Hello, I\'m happy to speak to you. How can I help you?","voice_settings":{"stability":0.5,"similarity_boost":0.5}}'
			body: JSON.stringify({
				text: input,
				voice_settings: { stability: 0.5, similarity_boost: 0.5 },
			}),
		};

		return fetch(
			`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}/stream`,
			options
		).then((response) => response.arrayBuffer());
	};

	// send request to D-ID
	const sendDIDRequest = async ({ input, imageUrl, voiceId }: DIDProps) => {
		const apikey =
			keys.DID_API_KEY || (await getConfig("DID_API_KEY", "string"));
		const authorization = "Basic " + apikey;

		// create headers for request to D-ID
		const headers = {
			"x-api-key-external": JSON.stringify({
				elevenlabs:
					keys.ELEVENLABS_API_KEY ||
					(await getConfig("ELEVENLABS_API_KEY", "string")),
			}),
			accept: "application/json",
			"content-type": "application/json",
			Authorization: authorization,
		};

		// create body for request to D-ID
		const body = {
			script: {
				type: "text",
				input,
				provider: {
					type: "elevenlabs",
					voice_id: voiceId,
				},
			},
			source_url: imageUrl,
		};

		return fetch("https://api.d-id.com/talks", {
			method: "POST",
			headers,
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then(
				(data) =>
					new Promise<string>((resolve, reject) => {
						if (!data || !data.id) {
							reject("Invalid response");
							return;
						}

						// limit the requests to 20
						let limit = 40;

						// send request to get the result
						const sendRequest = () => {
							fetch(`https://api.d-id.com/talks/${data.id}`, {
								method: "GET",
								headers,
							})
								.then((response) => response.json())
								.then((data) => {
									 //console.log("data", data);
									if (data.result_url) {
										resolve(data.result_url);
									} else if (limit) {
										limit--;
										setTimeout(sendRequest, 5000);
									} else {
										reject("Request limit reached");
									}
								});
						};
						// start the request
						sendRequest();
					})
			);
	};

	// create context value
	const value = {
		loadImages,
		personalities,
		images,
		avatars,
		sendDIDRequest,
		sendOpenAIRequest,
		sendElevenLabsStreamRequest,
		selectedCategory, // Include selectedCategory in the context value
		setSelectedCategory, // Provide a way to update selectedCategory
	};

	// return provider with value
	return (
		<SharktankContext.Provider value={value}>
			{children}
		</SharktankContext.Provider>
	);
};
